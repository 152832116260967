<template>
    <section>
        <div class="row mx-0 align-items-center mt-2 pr-3 mb-3">
            <div class="col-auto pr-0">
                <i class="icon-safe text-general f-22" />
            </div>
            <div class="col-3 pl-0">
                <el-select v-model="idCaja" filterable class="w-100" size="small" @change="listaTransferenciasCajas">
                    <el-option
                    v-for="item in cajas"
                    :key="item.id"
                    :label="item.nombre"
                    :value="item.id"
                    />
                </el-select>
            </div>
            <div v-if="$can('botones_flujo_dinero_cajas_gestionar')" class="col-auto px-1">
                <router-link :to="{name: 'admin.tesoreria.flujo-dinero.cajas.editar'}">
                    <div class="btn-edit br-8 border d-middle-center text-general">
                        <i class="icon-pencil-outline f-20" />
                    </div>
                </router-link>
            </div>
            <div v-if="idCaja != null" class="col-auto pr-0 ml-auto f-26 f-600 text-general d-middle">
                {{ separadorNumero(saldo) }}
                <small class="f-15 ml-2">
                    Saldo actual
                </small>
                <el-tooltip v-if="alertaVerificacion" placement="bottom" :content="`El saldo no se ha verificado hace más de ${horasUltimaVerificacion} horas`" effect="light">
                    <div class="rounded-circle ml-3 d-middle-center alert-circled" style="width:28px;height:28px;background-color:#FF1720;">
                        <i class="icon-calendar-alert f-18 text-white" />
                    </div>
                </el-tooltip>
            </div>
            <div v-if="idCaja != null && $can('boton_flujo_dinero_cajas_verificar_saldo')" class="col-auto">
                <div class="bg-general3 text-white f-14 br-20 px-2 cr-pointer" @click="verificarSaldo">
                    Verificar saldo
                </div>
            </div>
            <div v-if="idCaja != null && $can('boton_flujo_dinero_cajas_historial_saldo')" class="col-auto px-1">
                <el-tooltip placement="bottom" content="Historial de verificación" effect="light">
                    <div class="btn-edit br-8 border cr-pointer d-middle-center text-general" @click="historialVerificaciones">
                        <i class="icon-history f-20" />
                    </div>
                </el-tooltip>
            </div>
        </div>
        <div v-if="idCaja == null" class="row text-general-red justify-center bg-gris mt-2 br-10 mx-3 py-2">
            Por favor seleccione una caja
        </div>
        <div v-else class="row mx-0">
            <div class="col-12 px-0">
                <tabla-general :data="tableCajas" alto="calc(100vh - 335px)" class-header="text-general f-16" :usar-paginacion="false">
                    <template slot="cabecera-izquierda">
                        <div class="col-auto text-general f-18 f-600">
                            Ingresos a la caja
                        </div>
                        <div v-if="sinVerificar > 0" class="col text-right text-general f-18">
                            <i class="icon-alert-triangle text-warning f-18" />
                            {{ sinVerificar }} Días con ingreso faltante en este mes.
                        </div>
                    </template>
                    <template slot="adicionales-izquierda">
                        <el-table-column prop="dia" label="Día" sortable sort-by="fecha" width="180">
                            <template slot-scope="{ row }">
                                <div @click="verCajaDia(row)">
                                    <p class="cr-pointer text-general">
                                        {{ row.dia }}
                                    </p>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="Pagos registrados" prop="pagosDiaValor" sortable sort-by="pagosDiaValor" align="center" class-name="text-center" width="200">
                            <template slot-scope="{ row }">
                                {{ separadorNumero(row.pagosDiaValor) }} ({{ agregarSeparadoresNumero(row.pagosDiaContar) }})
                            </template>
                        </el-table-column>
                        <el-table-column label="Ingreso por pagos" sortable sort-by="tesoreriasDiaValor" align="center">
                            <template slot-scope="{ row }">
                                <div class="row mx-0">
                                    <div v-if="row.tesoreriasDiaRestante == 0" class="col-auto ml-auto text-general">
                                        {{ separadorNumero(row.tesoreriasDiaValor) }} ({{ agregarSeparadoresNumero(row.tesoreriasDiaContar) }})
                                    </div>
                                    <div v-else class="col-auto ml-auto text-warning">
                                        {{ separadorNumero(row.tesoreriasDiaValor) }} ({{ agregarSeparadoresNumero(row.tesoreriasDiaContar) }})
                                        <el-tooltip placement="bottom" effect="light">
                                            <div slot="content">
                                                <p>Faltan {{ separadorNumero(row.tesoreriasDiaRestante) }} <br /> por ingresar</p>
                                            </div>
                                            <i class="icon-alert-triangle text-warning f-18" />
                                        </el-tooltip>
                                    </div>
                                    <div v-if="(row.fecha == hoy || row.fecha == ayer) && row.tesoreriasDiaRestante != 0 && $can('boton_flujo_dinero_cajas_ingreso_tesoreria')" class="col-2 px-2 ml-4">
                                        <el-tooltip placement="right" content="Ingreso a caja" effect="light">
                                            <div class="br-4 text-white cr-pointer d-middle-center bg-general" style="height:18px;width:18px;" @click="ingresarDinero(row)">
                                                <i class="icon-plus" />
                                            </div>
                                        </el-tooltip>
                                    </div>
                                    <div v-if="$can('boton_flujo_dinero_cajas_historial_tesorerias')" class="col-auto ml-2">
                                        <el-tooltip placement="right" content="Historial de ingresos" effect="light">
                                            <i class="icon-history cr-pointer text-general f-20" @click="historialTesorerias(row)" />
                                        </el-tooltip>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="Retiros administrador" sortable sort-by="salidasDiaValor" align="center">
                            <template slot-scope="{ row }">
                                <div class="row mx-0">
                                    <div class="col-3 ml-auto">
                                        {{ separadorNumero(row.salidasDiaValor) }} ({{ agregarSeparadoresNumero(row.salidasDiaContar) }})
                                    </div>
                                    <div class="col-3 px-0 d-flex justify-center ml-4">
                                        <el-tooltip v-if="hoy == row.fecha && $can('boton_flujo_dinero_cajas_retiro_admin')" placement="bottom" content="Retiro / Ingreso manual" effect="light">
                                            <div class="br-4 text-white cr-pointer d-middle-center bg-general3" style="height:18px;width:18px;" @click="retiroManual">
                                                <i class="icon-plus" />
                                            </div>
                                        </el-tooltip>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                    </template>
                </tabla-general>
                <div class="row mx-0 justify-center py-1">
                    <div v-show="currentPage > 1" class="btn-paginar text-white p-2 cr-pointer" @click="desaumentarPag()">
                        <i class="icon-angle-left f-18" />
                    </div>
                    <div v-show="currentPage > 3" class="btn-paginar mx-1 p-2 cr-pointer" @click="irAlMesActual">
                        1
                    </div>
                    <div v-show="currentPage > 2" class="btn-paginar mx-1 p-2 cr-pointer" @click="desaumentarPag(2)">
                        {{ currentPage - 2 }}
                    </div>
                    <div v-show="currentPage > 1" class="btn-paginar mx-1 p-2 cr-pointer" @click="desaumentarPag(1)">
                        {{ currentPage - 1 }}
                    </div>
                    <div class="btn-paginar p-2 cr-pointer mx-1 paginar-activo">
                        {{ currentPage }}
                    </div>
                    <div class="btn-paginar p-2 cr-pointer mx-1" @click="aumentarPag(1)">
                        {{ currentPage + 1 }}
                    </div>
                    <div class="btn-paginar p-2 cr-pointer mx-1" @click="aumentarPag(2)">
                        {{ currentPage + 2 }}
                    </div>
                    <div class="btn-paginar text-white p-2 cr-pointer" @click="aumentarPag()">
                        <i class="icon-angle-right" />
                    </div>
                </div>
            </div>
        </div>
        <!-- Partials -->

        <modal ref="modalHistorialDinero" titulo="Historial de ingreso de dinero" no-aceptar no-cancelar tamano="modal-lg">
            <div class="row mx-0">
                <div class="col-12">
                    <tabla-general
                    :data="hisotrialTesoreria"
                    class-header="text-general f-16"
                    :usar-paginacion="false"
                    :mostrar-buscador="false"
                    :alto="ajustarAlto"
                    >
                        <template slot="adicionales-izquierda">
                            <el-table-column width="80">
                                <template slot-scope="{ row }">
                                    <div class="row mx-0 justify-center">
                                        <img :src="row.responsable.foto" width="36" height="36" class="obj-cover rounded-circle" />
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="Encargado" prop="encargado.nombre" sort-by="encargado.nombre">
                                <template slot-scope="{ row }">
                                    {{ row.responsable.nombre }}
                                </template>
                            </el-table-column>
                            <el-table-column label="Ingreso" prop="valor" sortable sort-by="valor" align="center" class-name="text-center">
                                <template slot-scope="{ row }">
                                    {{ separadorNumero(row.valor) }}
                                </template>
                            </el-table-column>
                            <el-table-column label="Fecha Ingreso" sort-by="created_at" sortable>
                                <template slot-scope="{ row }">
                                    <p>{{ formatearFecha(row.created_at, 'D MMM Y - HH:mmA') }}</p>
                                </template>
                            </el-table-column>
                        </template>
                    </tabla-general>
                </div>
            </div>
        </modal>
        <modal ref="modalHistorialVerificacion" titulo="Historial de verificación" tamano="modal-lg" no-aceptar no-cancelar>
            <tabla-general
            :data="historialVerificacion"
            :alto="ajustarAlto"
            class-header="text-general f-16"
            :mostrar-buscador="false"
            :usar-paginacion="false"
            >
                <template slot="adicionales-izquierda">
                    <el-table-column width="80">
                        <template slot-scope="{ row }">
                            <div class="row mx-0 justify-center">
                                <img :src="row.responsable.foto" width="36" height="36" class="obj-cover rounded-circle" />
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column width="120" label="Encargado" prop="responsable.nombre" sort-by="responsable.nombre" />
                    <el-table-column label="Saldo Anterior" prop="valor_teorico" sortable sort-by="valor_teorico" align="center" class-name="text-right" width="180">
                        <template slot-scope="{ row }">
                            {{ separadorNumero(row.valor_teorico) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Valor Verificado" prop="valor_real" sortable sort-by="valor_real" align="center" class-name="text-right" width="180">
                        <template slot-scope="{ row }">
                            {{ separadorNumero(row.valor_real) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Diferencia" prop="diferencia" sortable sort-by="diferencia" align="center" class-name="text-right" width="150">
                        <template slot-scope="{ row }">
                            <p :class="row.diferencia < 0 ? 'text-general-red' : row.diferencia > 0 ? 'text-green' : 'text-general'">
                                {{ row.diferencia > 0 ? '+' : '' }}
                                {{ separadorNumero(row.diferencia) }}
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column label="Fecha de verificación" width="220" sort-by="created_at" sortable>
                        <template slot-scope="{ row }">
                            <p>{{ formatearFecha(row.created_at, 'D MMM Y - HH:mmA') }}</p>
                        </template>
                    </el-table-column>
                </template>
            </tabla-general>
        </modal>
        <!-- Partials -->
        <modal-retiro-manual ref="modalRetiroManual" @ingreso="ingresoManual" />
        <modal-ingresar-dinero ref="modalIngresarDinero" @ingreso="ingresoCaja" />
        <modal-verificar-saldo ref="modalVerificarSaldo" @ingreso="verificarCaja" />
    </section>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex'
import Cajas from "~/services/flujoDinero/cajas";
export default {
    components: {
        modalRetiroManual: () => import('./partials/modalRetiroManual'),
        modalIngresarDinero: () => import('./partials/modalIngresarDinero'),
        modalVerificarSaldo: () => import('./partials/modalVerificarSaldo'),
    },
    data(){
        return{
            tableCajas: [],
            hisotrialTesoreria: [],
            historialVerificacion: [],
            dataColumns: [
                {valor: 'encargado', titulo: 'Encargado'},
                {valor: 'saldo_anterior', titulo: 'Saldo anterior'},
                {valor: 'valor_verificado', ancho: 200, titulo: 'Valor verificado'},
            ],
            currentPage: 1,
            saldo: 0,
            fechaActual: '',
            cajas: [],
            caja: {
                ultima_verificacion: {
                    created_at: null
                },
                verificacion_saldo: 0,
                created_at: null
            },
            idCaja: null,
            hoy: moment().format('Y-MM-DD'),
            ayer: moment().subtract(1,'days').format('Y-MM-DD'),
        }
    },
    computed: {
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
            id_moneda:'cedis/id_moneda',
        }),
        sinVerificar(){
            return this.tableCajas.filter(el => el.tesoreriasDiaRestante > 0).length
        },
        horasUltimaVerificacion(){
            let horas = 0
            horas = moment().diff(moment(_.get(this.caja.ultima_verificacion,'created_at', this.caja.created_at)),'hours')
            return horas
        },
        alertaVerificacion(){
            return this.horasUltimaVerificacion >= this.caja.verificacion_saldo
        },
        ajustarAlto(){
            return window.innerHeight <= 680 ? 'calc(100vh - 302px)' : 'calc(100vh - 564px)'   
        }
    },
    watch: {
        id_cedis(val,oldVal){
            if (oldVal == null) return
            if(val == null) return
            this.idCaja = null
            this.tableCajas = []
            this.selectCajas()
        },
        id_moneda(val,oldVal){
            if (oldVal == null) return
            if(val == null) return
            this.listaTransferenciasCajas()
        }
    },
    mounted(){
        this.montar()
    },
    methods: {
        async montar(){
            try {
                this.selectCajas()
            } catch (error){
                this.error_catch(error)
            }finally{
            }
        },
        
        async listaTransferenciasCajas(){
            try {
                const params = {
                    id_moneda: this.id_moneda,
                    id_caja: this.idCaja,
                    currentPage: this.currentPage - 1
                }
                const { data } = await Cajas.listaTransferenciasCajas(params)
                console.log({data});
                this.tableCajas = data.data.ingresosCaja
                this.saldo = data.data.saldo
                this.caja = data.data.caja
            } catch (error){
                this.error_catch(error)
            }
        },
        async historialTesorerias({ fecha }){
            try {
                this.$refs.modalHistorialDinero.toggle();
                const params = {
                    id_moneda: this.id_moneda,
                    id_caja: this.idCaja,
                    fecha
                }
                this.hisotrialTesoreria = []
                const { data } = await Cajas.historialTesorerias(params)
                this.hisotrialTesoreria = data.data.hisotrialTesoreria.filter(el => el.estado == 1)

            } catch (error){
                this.error_catch(error)
            }
        },
        async historialVerificaciones(){
            try {
                this.$refs.modalHistorialVerificacion.toggle();
                const params = {
                    id_moneda: this.id_moneda,
                    id_caja: this.idCaja,
                }
                const { data } = await Cajas.historialVerificaciones(params)
                this.historialVerificacion = data.data.historialVerificacion


            } catch (error){
                this.error_catch(error)
            }
        },
        async selectCajas(){
            try {
                const { data } = await Cajas.selectCajas()

                this.cajas = data.data.cajas
            } catch (error){
                this.error_catch(error)
            }
        },
        ingresarDinero(row){
            const params = {
                fecha: row.fecha,
                id_caja: this.idCaja,
                valor_pendiente: row.tesoreriasDiaRestante,
                sigla: this.caja.sigla,
                moneda: this.caja.idm_moneda,
            }
            this.$refs.modalIngresarDinero.toggle(params)
        },
        retiroManual(){
            const params = {
                id_caja: this.idCaja,
                sigla: this.caja.sigla,
                moneda: this.caja.idm_moneda,
            }
            this.$refs.modalRetiroManual.toggle(params);
        },
        verificarSaldo(){
            const params = {
                id_caja: this.idCaja,
                saldo: this.saldo,
                sigla: this.caja.sigla,
                moneda: this.caja.idm_moneda,
            }
            this.$refs.modalVerificarSaldo.toggle(params);
        },
        modalHistorialVerificacion(){
            this.$refs.modalHistorialVerificacion.toggle();
        },
        aumentarPag(num = 1){
            this.currentPage += num
            this.listaTransferenciasCajas()
        },
        desaumentarPag(num = 1){
            this.currentPage -= num
            this.listaTransferenciasCajas()
        },
        irAlMesActual(){
            this.currentPage = 1
            this.listaTransferenciasCajas()
        },
        ingresoCaja({ pago, saldo}){
            let index = this.tableCajas.findIndex(el => el.fecha == pago.fecha)
            let model = this.tableCajas.find(el => el.fecha == pago.fecha)

            model.pagosDiaValor
            model.tesoreriasDiaValor = model.tesoreriasDiaValor + pago.valor
            model.tesoreriasDiaContar = model.tesoreriasDiaContar + 1
            model.tesoreriasDiaRestante = model.tesoreriasDiaRestante - pago.valor

            this.saldo = saldo
            this.$set(this.tableCajas, index, model)
        },
        ingresoManual({ pago, saldo}){
            let model = this.tableCajas[0]
            if (pago.tipo == 0){
                model.salidasDiaValor = model.salidasDiaValor + +pago.valor
            }
            model.salidasDiaContar = model.salidasDiaContar + 1
            this.saldo = saldo
            this.$set(this.tableCajas, 0, model)
        },
        verificarCaja({ caja, saldo}){
            this.caja = caja
            this.saldo = saldo
        },
        verCajaDia(row){
            if (!this.$can('info_flujo_dinero_cajas_ver_caja')) return
            this.$router.push({name: 'admin.tesoreria.flujo-dinero.cajas.ver', params: { id_caja: this.idCaja }})
            this.$store.commit('flujoDinero/caja/setDia',row.dia)
            this.$store.commit('flujoDinero/caja/setFecha',row.fecha)
            this.$store.commit('flujoDinero/caja/setCaja',this.caja)
        },
    }
}
</script>
<style lang="scss" scoped>
a{
    text-decoration: none !important;
}
.btn-edit{
    width: 32px;
    background-image: linear-gradient(to bottom, #FFFFFF, #F3F5F7);
    height: 32px;
}
.active{
    color: red;
}
.btn-paginar{
    width: 40px;
    height: 40px;
    border-radius: 12px;
    background-color: #DBDBDB;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
}
.paginar-activo{
    color: #FFFFFF;
    background-color: var(--text-general) !important;
}
.text-green{
    color: #28D07B !important;
}
.icon-calendar-alert::before{
    display: flex !important ;
}
.alert-circled{
    animation: alert 3s infinite;
    box-shadow: 0 0 0 0 rgb(255, 0, 0);
	transform: scale(1);
}
@keyframes alert {
    0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
	}
}
</style>
